import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <h1 className="mono">LakeUnion.dev</h1>
        </div>
      </header>
    </div>
  );
}

export default App;
